import React, { Component } from 'react';
import './Menu.css';
import * as THREE from 'three';
import { gsap } from 'gsap';
import { Swipeable } from 'react-swipeable';
import Div100vh from 'react-div-100vh';
import { Link } from 'react-router-dom';

class Menu extends Component {
  state = {
    highlightedProject: null
  };

  threeCanvas = React.createRef();
  rootMenu = React.createRef();
  portfolioCards = [];
  preloadedCards = [];
  preloadedIndexes = [];
  spacing = 50;

  currentSelection = null;
  currentSelectionID = null;

  componentDidUpdate(prevProps) {
    if (this.props.showMenu !== prevProps.showMenu) {
      this.props.showMenu ? this.showMenu() : this.hideMenu();
    }
    if (this.props.portfolio && this.portfolioCards.length === 0) {
      //portfolio data incoming for the first time
      this.addPortfolioSceneObjects();
      this.addPreloadedSceneObjects();
    } else if (this.props.portfolio !== prevProps.portfolio) {
      for (const [index, pItem] of this.props.portfolio.entries()) {
        //animate items in as the media is loaded
        if (pItem.mediaPreloaded && !this.preloadedIndexes.includes(index)) {
          this.preloadedIndexes.push(index);
          const placeholderMesh = this.portfolioCards[index];
          const preMeshGroup = this.preloadedCards[index];
          const preMeshWithImage = preMeshGroup.children[0];
          const backingColMesh = preMeshGroup.children[1];

          this.fadeOutObject(placeholderMesh, placeholderMesh.material, 0);
          this.slideInObject(
            preMeshGroup,
            [preMeshWithImage.material, backingColMesh.material],
            0.5
          );
          gsap.to(preMeshWithImage.material, 1.0, {
            opacity: 0.7,
            delay: 1.0,
            ease: 'expo.out'
          });
        }
      }
    }
  }

  componentDidMount() {
    this.sceneSetup();
    this.startAnimationLoop();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.requestID);
  }

  sceneSetup = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.scene = new THREE.Scene();
    this.scene.rotation.y = 0.1;
    this.camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      -1000,
      1000
    );
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.renderer.setClearColor(0xe9e9e0);
    this.threeCanvas.appendChild(this.renderer.domElement);
    this.camera.zoom = 1;
    this.camera.position.set(10, 10, 10);
    this.camera.lookAt(this.scene.position);
    this.camera.updateProjectionMatrix();
  };

  startAnimationLoop = () => {
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    this.renderer.render(this.scene, this.camera);
  };

  handleWindowResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.camera.left = width / -2;
    this.camera.right = width / 2;
    this.camera.top = height / 2;
    this.camera.bottom = height / -2;
    this.renderer.setSize(width, height);

    this.camera.updateProjectionMatrix();
  };

  addPreloadedSceneObjects = () => {
    console.log('addPreloadedSceneObjects');
    const n = this.props.portfolio.length;
    const offset = 0 - (n * this.spacing) / 2;
    const loader = new THREE.TextureLoader();

    for (const [index, pItem] of this.props.portfolio.entries()) {
      var geometry = new THREE.PlaneGeometry(100, 100, 1);
      const material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        map: loader.load(pItem.image)
      });
      material.transparent = true;
      material.opacity = 0;
      var portPlane = new THREE.Mesh(geometry, material);
      portPlane.position.z = offset + index * this.spacing;

      //colour backing
      var colgeometry = new THREE.PlaneGeometry(100, 100, 1);
      const colmaterial = new THREE.MeshBasicMaterial({
        color: 0xe9e9e0
      });
      colmaterial.transparent = true;
      colmaterial.opacity = 0;
      var colportPlane = new THREE.Mesh(colgeometry, colmaterial);
      colportPlane.position.z = offset + index * this.spacing - 1;

      var group = new THREE.Group();
      group.add(portPlane);
      group.add(colportPlane);
      this.preloadedCards.push(group);

      this.scene.add(group);
    }
  };

  addPortfolioSceneObjects = () => {
    console.log('addPortfolioSceneObjects');
    const n = this.props.portfolio.length;
    const offset = 0 - (n * this.spacing) / 2;
    for (const [index] of this.props.portfolio.entries()) {
      var geometry = new THREE.PlaneGeometry(100, 100, 1);
      var material = new THREE.MeshBasicMaterial({
        color: 0xa6a68b
      });
      material.transparent = true;
      material.polygonOffset = true;
      material.polygonOffsetFactor = -0.1;
      material.opacity = 0;
      var portPlane = new THREE.Mesh(geometry, material);
      portPlane.position.z = offset + index * this.spacing;

      this.portfolioCards.push(portPlane);
      this.scene.add(portPlane);
      this.slideInObject(portPlane, material, 0.05 * (n - index));
    }
  };

  slideInObject = (object, material, delay) => {
    gsap.fromTo(
      material,
      0.3,
      {
        opacity: 0
      },
      {
        opacity: 1,
        delay: delay,
        ease: 'power2.easeOut'
      }
    );

    gsap.fromTo(
      object.position,
      1.5,
      {
        y: 50
      },
      {
        y: 0,
        delay: delay,
        ease: 'elastic.out(1, 0.5)'
      }
    );
  };

  slideOutObject = (object, material, delay) => {
    gsap.to(material, 0.2, {
      opacity: 0,
      delay: delay + 0.3,
      ease: 'power2.easeOut'
    });

    gsap.to(object.position, 0.5, {
      y: -100,
      delay: delay,
      ease: 'power2.easeOut',
      onComplete: () => {
        this.scene.remove(object);
      }
    });
  };

  fadeOutObject = (object, material, delay) => {
    gsap.to(material, 0.2, {
      opacity: 0,
      delay: delay + 0.3,
      ease: 'power2.easeOut',
      onComplete: () => {
        this.scene.remove(object);
      }
    });
  };

  hoverObject = object => {
    gsap.to(object.position, 0.5, {
      y: 50,
      delay: 0,
      ease: 'elastic.out(1, 1)'
    });
    gsap.to(object.children[0].material, 0.5, {
      opacity: 1,
      delay: 0,
      ease: 'expo.out'
    });
  };

  unhoverObject = object => {
    gsap.to(object.position, 1.0, {
      y: 0,
      delay: 0,
      ease: 'elastic.out(1, 1)'
    });
    gsap.to(object.children[0].material, 1.0, {
      opacity: 0.7,
      delay: 0,
      ease: 'expo.out'
    });
  };

  onWheel = event => {
    if (this.props.loadedMediaAssets) {
      const speed = 0.6;
      const zPos = this.camera.position.z + event.deltaY * speed;

      this.animateMenuCamera(zPos);
    }
  };

  onSwiping = event => {
    //console.log('deltaX: ' + event.deltaX);
    //console.log('velocity: ' + event.velocity);
    if (this.props.loadedMediaAssets) {
      const speed = 0.3; //0.3 when tweening
      const zPos =
        this.camera.position.z - event.deltaX * speed * event.velocity;
      this.animateMenuCamera(zPos);
    }
  };

  animateMenuCamera = zPos => {
    const zPerUnit = 40;
    const zRange = zPerUnit * this.props.portfolio.length;
    const zFloor = -zRange / 2;
    const zCeil = zRange / 2;
    if (zPos > zFloor && zPos < zCeil) {
      gsap.to(this.camera.position, 0.5, {
        z: zPos,
        delay: 0,
        ease: 'expo.out',
        overwrite: true
      });
      this.selectCardForCameraPosition(zPos);
    }
  };

  selectCardForCameraPosition = (incomingZPos = null) => {
    const zPos = incomingZPos ? incomingZPos : this.camera.position.z;
    const roughSize = 40;
    const n = this.props.portfolio.length;
    const zRange = n * roughSize;
    const zFloor = -(zRange / 2);
    let activeIndex = Math.round(((zPos - zFloor) / zRange) * n);
    if (activeIndex < 0) activeIndex = 0; //null;
    if (activeIndex > n - 1) activeIndex = n - 1; //null;

    const activeCard =
      activeIndex != null ? this.preloadedCards[activeIndex] : null;
    if (this.currentSelection !== activeCard) {
      if (this.currentSelection) {
        this.unhoverObject(this.currentSelection);
      }
      this.currentSelection = activeCard;
      this.setState({ highlightedProject: this.props.portfolio[activeIndex] });
      if (activeCard) this.hoverObject(activeCard);
    }
  };

  hideMenu = () => {
    gsap.to(this.rootMenu.current, 1, {
      opacity: 0,
      ease: 'expo.out',
      overwrite: true,
      onComplete: () => {
        this.rootMenu.current.style.display = 'none';
      }
    });
  };

  showMenu = () => {
    this.rootMenu.current.style.display = 'block';
    gsap.to(this.rootMenu.current, 1, {
      opacity: 1,
      ease: 'expo.out',
      overwrite: true,
      onComplete: () => {}
    });
  };

  render() {
    return (
      <div ref={this.rootMenu}>
        <Swipeable
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
          stopPropagation={true}
          preventDefaultTouchmoveEvent={true}
          onSwiping={this.onSwiping}
        >
          <Div100vh className="Menu">
            <div className="topTitle">
              <h2>Damien Oliver</h2>
              <h5>Creative Technologist / Interactive Artist</h5>
            </div>
            <Link
              to={
                this.state.highlightedProject
                  ? '/portfolio/' + this.state.highlightedProject.id
                  : '/portfolio'
              }
              onClick={() => {
                this.props.onMenuSelectionChange();
              }}
            >
              {this.state.highlightedProject ? (
                <div className="highlightedProject">
                  <h2>{this.state.highlightedProject.title}</h2>
                  <h5>{this.state.highlightedProject.subtitle}</h5>
                </div>
              ) : null}
              <div
                className={
                  !this.props.loadedData || !this.props.loadedMediaAssets
                    ? 'loadingStatus lsShow'
                    : 'loadingStatus lsHide'
                }
              >
                {!this.props.loadedData ? 'database query' : null}
                {this.props.loadedData && !this.props.loadedMediaAssets
                  ? 'caching media assets'
                  : null}
                {this.props.loadedData && this.props.loadedMediaAssets
                  ? 'done!'
                  : null}
              </div>
              <div
                className="threeCanvas"
                ref={instance => (this.threeCanvas = instance)}
                onWheel={this.onWheel}
              ></div>
            </Link>
          </Div100vh>
        </Swipeable>
      </div>
    );
  }
}

export default Menu;
