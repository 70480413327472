import React, { Component } from 'react';
import './Portfolio.css';
import FluidGallery from 'react-fluid-gallery';
import PortfolioDetails from './PortfolioDetails/PortfolioDetails';
import PortfolioNavigation from './PortfolioNavigation/PortfolioNavigation';
import { isMobile } from 'react-device-detect';

class Portfolio extends Component {
  state = {
    currentIndex: null,
    currentProject: null
  };

  gallery = React.createRef();

  componentDidMount() {
    console.log('componentDidMount');
    //startAtIndex
    if (this.props.activeID && this.props.portfolio) {
      console.log('new activeID data');
      const newProjectIndex = this.props.portfolio
        .map(e => e.id)
        .indexOf(this.props.activeID);
      console.log('newProjectIndex: ' + newProjectIndex);
      if (newProjectIndex != null) {
        this.setState({
          currentProject: this.props.portfolio[newProjectIndex],
          currentIndex: newProjectIndex
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.portfolio !== prevProps.portfolio) {
      console.log('new portfolio data');
      this.setState({
        currentProject: this.props.portfolio[this.state.currentIndex]
      });
    }
    if (this.props.activeID !== prevProps.activeID) {
      console.log('new active ID: ' + this.props.activeID);
      const newIndex = this.indexForID(this.props.activeID);
      if (newIndex != null) this.gallery.goto(newIndex);
    }
  }

  indexForID = projectID => {
    return this.props.portfolio.map(e => e.id).indexOf(projectID);
  };

  _onChange = index => {
    console.log('FluidGallery.onChange', index);
    if (this.props.portfolio != null) {
      this.setState({
        currentProject: this.props.portfolio[index],
        currentIndex: index
      });
    }
  };

  onManualLinkChange = index => {
    console.log('onManualLinkChange:' + index);
    this.gallery.goto(index);
  };

  onNextGallery = () => {
    this.gallery.next();
  };

  onPrevGallery = () => {
    this.gallery.prev();
  };

  render() {
    const media = this.props.portfolio
      ? this.props.portfolio.map(pItem =>
          isMobile ? pItem.image : pItem.video
        )
      : null;
    const fluidGalleryContent =
      media != null && this.state.currentIndex != null ? (
        <FluidGallery
          style={{ width: '100vw', height: '100vh' }}
          slides={media}
          onChange={this._onChange}
          startAt={this.state.currentIndex}
          ref={instance => (this.gallery = instance)}
        />
      ) : null;
    return (
      <div className="Portfolio">
        <PortfolioDetails project={this.state.currentProject} />
        <PortfolioNavigation
          portfolio={this.props.portfolio}
          index={this.state.currentIndex}
          indexLinkCallback={this.onManualLinkChange}
          nextCallback={this.onNextGallery}
          prevCallback={this.onPrevGallery}
        />
        {fluidGalleryContent}
      </div>
    );
  }
}

export default Portfolio;
