import * as firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyCUZ0o020cGvVtJApbanuOP-N72H1EnnOc',
  authDomain: 'damienoliver.firebaseapp.com',
  databaseURL: 'https://damienoliver.firebaseio.com',
  projectId: 'damienoliver',
  storageBucket: 'damienoliver.appspot.com',
  messagingSenderId: '831863978950',
  appId: '1:831863978950:web:76e6d67b87697e686010c7',
  measurementId: 'G-VDWZZKJQ41'
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
