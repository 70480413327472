import React, { Component } from 'react';
import './PortfolioDetails.css';

class PortfolioDetails extends Component {
  render() {
    return this.props.project ? (
      <div className="PortfolioDetails">
        <h1>{this.props.project.title}</h1>
        <h3>{this.props.project.subtitle}</h3>
        <div className="pdDescription">{this.props.project.description}</div>
      </div>
    ) : null;
  }
}

export default PortfolioDetails;
