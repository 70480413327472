import React, { Component } from 'react';
import './PortfolioNavigation.css';
import { Link } from 'react-router-dom';

class PortfolioNavigation extends Component {
  render() {
    if (this.props.portfolio == null || this.props.index == null) return null;

    const nextIndex =
      this.props.index < this.props.portfolio.length - 1
        ? this.props.index + 1
        : 0;
    const prevIndex =
      this.props.index > 0
        ? this.props.index - 1
        : this.props.portfolio.length - 1;
    const nextProject = this.props.portfolio[nextIndex];
    const prevProject = this.props.portfolio[prevIndex];

    return (
      <div className="PortfolioNavigation">
        <Link
          to={'/portfolio/' + prevProject.id}
          className="portNav navPrevious"
          onClick={() => {
            // this.props.indexLinkCallback(prevIndex);
            // this.props.prevCallback();
          }}
        >
          {prevProject.title}
        </Link>
        <Link
          to={'/portfolio/' + nextProject.id}
          className="portNav navNext"
          onClick={() => {
            // this.props.indexLinkCallback(nextIndex);
            // this.props.nextCallback();
          }}
        >
          {nextProject.title}
        </Link>
      </div>
    );
  }
}

export default PortfolioNavigation;
