import React, { Component } from 'react';
import './App.css';
import { Route, Switch, withRouter } from 'react-router-dom';
import firebase from './firebase';
import Portfolio from './components/Portfolio/Portfolio';
import Menu from './components/Menu/Menu';
import axios from 'axios';
import { isMobile } from 'react-device-detect';

class App extends Component {
  state = {
    loadedData: false,
    loadedMediaAssets: false,
    portfolio: null,
    error: null,
    showMenu: true
  };

  preloadMediaAssets = () => {
    for (const [index, pItem] of this.state.portfolio.entries()) {
      this.fetchMedia(pItem, index);
    }
  };

  fetchMedia = (pItem, pIndex) => {
    axios
      .get(isMobile ? pItem.image : pItem.video)
      .then(result => {
        const newPortfolio = this.state.portfolio.slice();
        newPortfolio[pIndex].mediaPreloaded = true;
        this.setState({ portfolio: newPortfolio });

        this.onMediaAssetLoaded(pItem);

        const numOfLoadedAssets = this.state.portfolio.filter(
          i => i.mediaPreloaded
        ).length;
        if (numOfLoadedAssets === this.state.portfolio.length) {
          this.onAllAssetsLoaded();
          this.updateMenuStateByLocation(this.props.location);
        }
      })
      .catch(error => {
        console.log('error: ' + error);
      });
  };

  onMediaAssetLoaded = asset => {
    console.log('onMediaAssetLoaded: ' + asset.title);
  };

  onAllAssetsLoaded = () => {
    console.log('onAllAssetsLoaded');
    setTimeout(() => {
      //delay to avoid dropped frames in animation
      this.setState({ loadedMediaAssets: true });
    }, 1500);
  };

  updateMenuStateByLocation = location => {
    console.log('You changed the page to: ' + location.pathname);

    //show hide menu based on location
    this.setState(
      location.pathname === '/' ? { showMenu: true } : { showMenu: false }
    );
  };

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.updateMenuStateByLocation(location);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const fetchData = async () => {
      const db = firebase.firestore();
      const data = await db
        .collection('portfolio')
        .where('status', '==', 'published')
        .get();
      this.setState({
        portfolio: data.docs.map(doc => ({ ...doc.data(), id: doc.id })),
        loadedData: true
      });

      setTimeout(() => {
        //fake delay
        this.preloadMediaAssets();
      }, 1000);
    };
    fetchData();
  }

  menuSelectionChanged = () => {
    console.log('menuSelectionChanged');
    this.setState({ showMenu: false });
  };

  render() {
    return (
      <div className="App">
        <Menu
          loadedData={this.state.loadedData}
          loadedMediaAssets={this.state.loadedMediaAssets}
          portfolio={this.state.portfolio}
          onMenuSelectionChange={this.menuSelectionChanged}
          showMenu={this.state.showMenu}
        />
        <Switch>
          <Route path="/portfolio/:id">
            {({ match }) =>
              this.state.loadedMediaAssets ? (
                <Portfolio
                  portfolio={this.state.portfolio}
                  activeID={match.params.id}
                />
              ) : null
            }
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
